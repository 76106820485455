
/*
Color scheme
#17252A
#2B7A78
#3AAFA9
#DEF2F1
#FEFFFF
*/

body {
  font-family: Roboto, sans-serif;
  background: #3AAFA9;
  color: #FEFFFF;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

header {
  font-weight: 500;
  font-size: small;
  padding: 0.1rem 0.5rem;
  margin-bottom: 0.2rem;
  background: #FEFFFF;
  color: #2B7A78;
}

hr {
  border: none;
  border-bottom: 1px solid #3AAFA9;
}

#app {
  margin: 0;
  padding: 0;
}

.icon-button {
  display: inline;
  margin-left: 0.25rem;
  min-width: 2rem;
  padding: 0 0.5rem;
  font-size: x-small;
  background: #2B7A78;
  border-radius: 2px;
  cursor:pointer;
}

.icon-button:hover {
  background: #DEF2F1;
  color: #2B7A78;
}

.icon-button .fa {
  margin-right: 0.25rem;
}

.section-title {
  padding: 1rem;
}

.section-title .title-header {
  font-size: x-large;
}

.section-title .title-notes {
  text-align: right;
  font-weight: 300;
  font-size: x-small;
  line-height: 1.3;
}

.section-users {
  border: 2px solid #FEFFFF;
  min-width: 16rem;
  margin: 0 1rem;
}

.section-users input {
  background: #2B7A78;
  padding: 0.2rem 0.5rem;
  border: none;
  outline: none;
  resize: none;
  color: #FEFFFF;
}

.section-users .self {
  color: #DEF2F1;
  font-weight: 500;
}

.section-users .user-item {
  line-height: 1.5rem;
  height: 2.0em;
  padding: 0 0.5rem;
  border-bottom: 1px solid #DEF2F1;
}

.section-users .user-item:last-child {
  border-bottom: none;
}

.section-info {
  border: 2px solid #FEFFFF;
  width: 240px;
  min-height: 10rem;
  margin: 0 1rem;
  font-family: 'Roboto Mono', monospace;
  font-size: small;
}

.section-info .info-block {
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.1rem;
  background: #FEFFFF;
  color: #17252A;
  overflow-x: hidden;
  font-size: x-small;
}

.info-block header {
  color: #17252A;
  font-size: x-small;
  padding: 0;
}

.info-row {
  display: flex;
  align-items: center;
}

.info-name {
  flex-grow: 1;
  font-weight: 500;
}

.info-value {
  flex-grow: 0;
}

.info-value pre {
  margin: 0;
}

.details-link {
  text-decoration: underline;
  color: #2B7A78;
  cursor: pointer;
}

.details-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.details-content {
  width: 80vw;
  max-height: 80vh;
  background-color: #FEFFFF;
  margin: 5rem auto;
  border: 1px solid #2B7A78;
  box-shadow: 2px 2px 2px 1px #17252a20;
}

.details-content header {
  border-bottom: 1px solid #2B7A78;
  color: #FEFFFF;
  background-color: #2B7A78;
  padding: 0.5rem;
  text-align: right;
}

.details-content-body {
  padding: 0.5rem;
}

.close-button {
  font-size: x-large;
  cursor: pointer;
}

.section-messages {
  border: 2px solid #FEFFFF;
  padding: 0.5rem;
}

.section-messages .message {
  margin: 0.2rem 0;
  border-radius: 0.5rem;
  max-width: 80%;
}

.section-messages .status-message {
  background: #2B7A78;
  color: #DEF2F1;
  font-size: x-small;
  font-weight: 300;
  padding: 0.2rem 0.5rem;
}

.section-messages .text-message .username {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  font-weight: 500;
  font-size: x-small;
  padding: 0.2rem 0.5rem;
  margin-bottom: 0.2rem;
}

.section-messages .text-message .content {
  font-size: small;
  font-weight: 300;
  font-family: 'Roboto Mono', monospace;
  padding: 0.1rem 0.5rem 0.2rem 0.5rem;
  margin: 0;
}

.section-messages .text-message.sent-message {
  background: #2B7A78;
  color: #DEF2F1;
  border: 1px solid #DEF2F1;
  align-self: flex-end;
}

.section-messages .text-message.sent-message .username {
  text-align: right;
}

.section-messages .text-message.sent-message .content {
  text-align: right;
  color: #FEFFFF;
}

.section-messages .text-message.received-message {
  background: #FEFFFF;
  color: #2B7A78;
  border: 1px solid #2B7A78;
  align-self: flex-start;
}

.section-messages .text-message.received-message .username {
  text-align: left;
}

.section-messages .text-message.received-message .content {
  text-align: left;
  color:#17252A;
}

.section-input {
  border: 2px solid #FEFFFF;
  border-top: 0;
}

.section-input textarea {
  background: #2B7A78;
  padding: 0.5rem;
  border: none;
  outline: none;
  resize: none;
  color: #FEFFFF;
}

.section-input .icon-button {
  margin-left: 0;
  padding: 0.5rem;
}

.section-local-media {
  margin: 0 1rem 1rem 1rem;
}

.section-local-media .video {
  width: 240px;
  height: 180px;
  border: 2px solid #FEFFFF;
}

.section-remote-media {
  margin-bottom: 1rem;
}

.section-remote-media .video-container {
  margin-right: 1rem;
}

.section-remote-media .video {
  width: 320px;
  height: 240px;
  border: 2px solid #FEFFFF;
}


.section-console {
  font-family: 'Roboto Mono', monospace;
  font-size: small;
  padding: 0.5rem;
  height: 12rem;
  background: #17252A;
  color: #FEFFFF;
}

.console-item .time {
  width: 16em;
}