.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.shrink-0 {
  flex-shrink: 0;
}

.items-center {
  align-items: center;
}

.self-center {
  align-self: center;
}

.self-start {
  align-self: start;
}

.self-end {
  align-self: end;
}

.basis-0 {
  flex-basis: 0;
}

.basis-1\/5 {
  flex-basis: 20%;
}

.basis-1\/4 {
  flex-basis: 25%;
}

.h-screen {
  height: 100vh;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-scroll {
  overflow: scroll;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.m-0 {
  margin: 0;
}

.mb-4 {
  margin-bottom: 1rem;
}

.hidden {
  display: none;
}